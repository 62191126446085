<template>
  <vx-card :title="this.pageTitle" :key="componentKey">
    <template slot="actions">
      <div class="flex">
        <vs-button
          v-if="$can('create', 'store')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-plus-square"
          @click="createEntry()"
        >
          Agregar Sucursal
        </vs-button>
        <vs-button
          size="small" color="success" type="border" icon-pack="feather" icon="icon-download"
          class="ml-3"
          @click="showReportPopUp()"
        >
          Reporte de Sucursales
        </vs-button>
      </div>
    </template>

    <div v-if="$can('list', 'store')" class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
          <vs-divider/>
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4" @change="getEntries">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="institution">Institución</vs-th>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="category">Categoría</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <p class="font-medium">{{ record.institution.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.category.name }}</p>
              </vs-td>
              <vs-td>
                <div class="flex" v-if="!recycleBin">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'store') || $can('update', 'store'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="retrieveEntry(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat" icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'store')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
                <div class="flex" v-else>
                  <vx-tooltip text="Restaurar">
                    <vs-button
                        :disabled="!($can('create', 'store') || $can('update', 'store'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                        @click="confirmRestore(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>

    <vs-popup title="Reporte de Sucursales" :active.sync="popupActive" button-close-hidden>
      <template>
        <div class="vx-row">
          <div class="total vx-col w-full">
            <label class="w-full select-large">
              Destinatarios <small>(<i>Presione enter para confirmar cada uno de los destinatarios</i>)</small>
            </label>
            <vs-chips
              color="rgb(145, 32, 159)" placeholder="Destinatarios" v-model="reportSettings.recipients"
              remove-icon="delete_forever" class="mt-1 recipients-list"
            >
              <vs-chip
                :key="recipient"
                @click="remove(recipient)"
                v-for="recipient in reportSettings.recipients" closable close-icon="delete">
                {{ recipient }}
              </vs-chip>
            </vs-chips>
          </div>
        </div>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <div class="flex flex-wrap justify-between">
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-button size="small" color="danger" type="border" class="mr-3 mb-2" @click="popupDismiss">
                  Cancelar
                </vs-button>
                <vs-button size="small" class="mb-2" @click="requestReportGeneration">
                  Generar Reporte
                </vs-button>
              </vs-row>
            </div>
          </div>
        </div>
      </template>
    </vs-popup>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import FilterGroups from '@/components/filter-groups/FilterGroups';
  import * as notificationMixins from "./../../utils/notificationMixins.js";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("stores");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("stores");
  const STATES_CLIENT = new CoreClient("states");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");

  export default {
    data() {
      return {
        componentKey: 0,
        pageTitle: "Agencias y Sucursales",
        entries: [],
        institutions: [],
        queryStrings: {},
        filterGroups: [
          {
            name: "institution_id",
            label: "Instituciones",
            filters: []
          },
          {
            name: "category_id",
            label: "Categoría",
            filters: []
          },
        ],
        recycleBin: false,
        popupActive: false,
        reportSettings: {
          recipients: [],
        }
      };
    },

    mounted() {
      this.$vs.loading();
      this.getCategories(false)
        .then(() => {
          this.getEntries(false)
            .then(() => {
              this.getInstitutions(false)
                .then(() => {
                  this.$vs.loading.close();
                });
            });
        });
    },

    methods: {
      remove (recipient) {
        this.reportSettings.recipients.splice(this.reportSettings.recipients.indexOf(recipient), 1)
      },

      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({fromRecycleBin: this.recycleBin, queryStrings: this.queryStrings})
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await INSTITUTIONS_CLIENT.all()
          .then(response => {
            response.data.forEach(element => {
              this.filterGroups[0].filters.push({
                value: element.id,
                label: element.name
              });
            });
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "simple"}})
          .then(response => {
            response.data.forEach(element => {
              this.filterGroups[1].filters.push({
                value: element.id,
                label: element.name
              });
            });
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteEntry(entryId) {
        if (entryId !== undefined && entryId !== null && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
            .then(response => {
              notifier.notification("deleted");
              this.getEntries()
                .then(() => {
                  this.$vs.loading.close();
                });
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      confirmDelete(entry) {
        notificationMixins.DeleteEntryNotification.fire()
          .then(result => {
            if (result.value) this.deleteEntry(entry.id);
            else this.componentKey += 1;
          });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      confirmRestore(entry) {
        notificationMixins.RestoreEntryNotification.fire()
            .then(result => {
              if (result.value) {
                if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                  this.$vs.loading();
                  ENTRIES_CLIENT.restore({pathParameters: {entryId: entry.id}})
                      .then(response => {
                        notifier.notification("restored");
                        this.getEntries();
                      })
                      .catch(error => {
                        notifier.alertMessage("error");
                      });
                  this.$vs.loading.close();
                }
              }
            });
      },

      createEntry() {
        this.$router.push({name: "create-store"});
      },

      retrieveEntry(entryId) {
        this.$router.push({name: "store", params: {id: entryId}});
      },

      showReportPopUp() {
        this.popupActive = true;
      },

      async requestReportGeneration() {
        this.$vs.loading();
        await ENTRIES_CLIENT.requestReportGeneration(
          {
            data: {
              recipients: this.reportSettings.recipients,
              attributes: ["institution", "name", "category", "address", "geolocation", "description"]
            }
          }
        )
          .then(response => {
            this.$vs.loading.close();
            notifier.notification("created");
            this.popupDismiss();
          })
          .catch(error => {
            this.$vs.loading.close();
            notifier.alertMessage("error");
          });
      },

      popupDismiss() {
        this.popupActive = false;
        this.reportSettings = {
          recipients: []
        }
      }
    },

    components: {
      'filter-groups': FilterGroups
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
